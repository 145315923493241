@import 'two-app-ui/build/esm/src/scss/theme/_variables';

#layout-wrapper{
  background-color: $shade300;
}

#content{
  width: 100%;
  flex: 1 1 auto;
}
.p-dropdown-panel {
  max-width: 100%;
  .p-dropdown-item {
    white-space: pre-wrap;
  }
}
.app-page {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}
